import { Link } from "gatsby"
import React from "react"

const Footer: React.FC = () => {
  return (
    <footer className="flex flex-col items-center bg-white">
      <div className="w-full max-w-5xl px-4">
        <div className="flex flex-col items-center justify-between py-10 md:flex-row">
          <div className="flex flex-col items-center mb-10 md:mb-0 md:items-start">
            <Link
              className="text-4xl font-bold text-secondary font-logo"
              to="/"
            >
              LEMONADE
            </Link>
            <div className="mt-2 font-bold text-center text-secondary">
              <span>リモートで働くチームへの</span>
              <span>コラボレーションツール</span>
            </div>
          </div>

          <div className="flex flex-col items-center text-secondary md:items-start">
            <Link className="my-2 hover:opacity-80" to="/#download">
              お問い合わせ
            </Link>
            <Link className="my-2 hover:opacity-80" to="/privacy">
              プライバシーポリシー
            </Link>
            <a
              className="my-2 hover:opacity-80"
              href="https://aifie.co.jp"
              target="_blank"
            >
              会社概要
            </a>
          </div>
        </div>
      </div>

      <div className="w-full py-2 text-sm text-center bg-primary text-secondary">
        @ 2020 aifie inc.
      </div>
    </footer>
  )
}

export default Footer
