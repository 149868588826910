import React from "react"

import Footer from "components/layout/Footer"
import Header from "components/layout/Header"

import styles from "./Document.module.css"

const Document: React.FC<{ html: string }> = ({ html }) => {
  return (
    <>
      <Header />
      <div className="flex flex-col items-center justify-center bg-background-color">
        <div className="w-full max-w-3xl px-2 py-8">
          <div
            className={styles.container}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Document
