import { Link } from "gatsby"
import React from "react"

const Header: React.FC = () => {
  return (
    <div className="flex flex-col items-center bg-primary">
      <header className="flex items-center justify-between w-full max-w-5xl px-4 py-4">
        <Link className="text-2xl font-bold text-secondary font-logo" to="/">
          LEMONADE
        </Link>
      </header>
    </div>
  )
}

export default Header
